
import { defineComponent } from 'vue'

import Logo from '@/assets/svg/logo.svg?inline'
import Login from '@/components/forms/Login.vue'

export default defineComponent({
  name: 'LoginSection',
  data () {
    return {
    }
  },
  components: {
    Login,
    Logo
  },
  methods: {
  }
})
