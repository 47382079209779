
import { defineComponent } from 'vue'
import LoginSection from '@/components/sections/LoginSection.vue'

export default defineComponent({
  name: 'Login',
  components: {
    LoginSection
  }
})
