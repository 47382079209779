<template>
  <div class="login">
    <div class="card card-container">
      <Form
        @submit="handleLogin"
        :validation-schema="schema"
      >
        <div class="form-group">
          <Field
            name="email"
            type="text"
            placeholder="Email"
            class="form-control"
          />
          <ErrorMessage
            name="email"
            class="error-feedback"
          />
          <div v-if="message">
            <AlertBlock :message="message[0].email" />
          </div>
        </div>
        <div class="form-group">
          <Field
            name="password"
            type="password"
            placeholder="Password"
            class="form-control password"
          />
          <ErrorMessage
            name="password"
            class="error-feedback"
          />
          <div v-if="message">
            <AlertBlock :message="message[0].password" />
          </div>
        </div>

        <div class="login__buttons">
          <div class="form-group">
            <button class="login__button-login">
              <span class="spinner-border spinner-border-sm"></span>
              <span>Log in</span>
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'

import AlertBlock from '@/components/molecules/AlertBlock.vue'

export default defineComponent({
  name: 'Login',
  components: {
    Form,
    Field,
    ErrorMessage,
    AlertBlock
  },
  data () {
    const schema = yup.object().shape({
      email: yup.string().required('Login is required!'),
      password: yup.string().required('Password is required!')
    })

    return {
      message: '',
      schema
    }
  },
  methods: {
    handleLogin (user) {
      this.$store.dispatch('auth/login', user).then(
        (res) => {
          console.log('res', res.token)
          localStorage.setItem('user', JSON.stringify(res.token))
          this.$router.push('/dashboard/welcome')
        },
        (error) => {
          this.message = error.response.data.errors
        }
      )
    }
  }
})
</script>

<style lang="scss" scoped>
.login {
  &__button {
    @include third-button;
    width: 400px;
    margin: 10px 0;

    &:hover {
      @include hover-third-button;
    }
    &:active {
      background: #ffeb36;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 100px;
      color: #1e1f23;
    }
  }

  &__forgot-password {
    font-size: 16px;
    line-height: 19px;
    color: $thirdColor;

    &:hover {
      @include second-hover-link;
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    padding-top: 10px;
  }

  &__recaptcha {
    padding-top: 20px;
  }

  &__button-sing-up {
    @include second-button;
    width: 140px;
    height: 40px;
  }

  &__button-login {
    @include first-button;
    width: 160px;
    height: 40px;
  }
}
.form-group {
  position: relative;
  padding-bottom: 10px;
}

@media (max-width: $break-sm) {
  .login {
    &__button-sing-up {
      width: 120px;
    }

    &__button-login {
      width: 130px;
    }

    ::v-deep(.form-control) {
      width: 260px;
    }
  }
}
</style>
